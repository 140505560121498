var arr = [
  {
    title: 'الاشتراك في الاداة',
    route: 'subscribe',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'أرشيف الإرسال',
    route: 'archive',
    icon: 'SendIcon',
  },
  {
    title: 'سجل التقارير',
    route: 'reports-archive',
    icon: 'ArchiveIcon',
  },
  {
    title: 'تواصل معنا',
    route: 'contact',
    icon: 'PhoneIcon',
  },
  {
    title: 'الاشتراك الحالي',
    route: 'home',
    icon: 'CheckIcon',
  },
  {
    title: 'شرح الاستخدام',
    href: 'https://brmja.com/howto#naqar',
    icon: 'VideoIcon',
  },
], arr2 = [], user = JSON.parse(localStorage.getItem("user"));

arr.forEach(function (e) {

  if (e.g == "resller") {
    if (user?.reseller_mode) {
      arr2.push(e)
    }
  } else {
    arr2.push(e)
  }

})


export default arr2
